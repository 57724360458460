import React from 'react';
import PropTypes from 'prop-types';
import VerticalTextBlock from '../VerticalTextBlock';

import './SeoTextSection.scss';


const SeoText = ({ children, customVerticalText, dotsClass }) => (
  <section className="seo-text-section">
    <div className="seo-text-section__container container">
      <VerticalTextBlock
        sectionClassName="seo-text"
        text={customVerticalText}
        dotsStyle={dotsClass}
      />

      <div className="seo-text-section__content">
        {children}
      </div>
    </div>
  </section>
);

SeoText.propTypes = {
  customVerticalText: PropTypes.string,
  children: PropTypes.node,
  dotsClass: PropTypes.string,
};

SeoText.defaultProps = {
  customVerticalText: 'TakeAway Essay',
  children: undefined,
  dotsClass: 'style-4',
};

export default SeoText;
