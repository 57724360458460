import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Events, scroller } from 'react-scroll';


class SeoTextContent extends React.Component {
  state = {
    isSeoTextOpen: true,
  };

  /**
   * Is it so necessary to add complex scroll behavior for simple purpose?
   */
  toggleSeoText = () => {
    const { isSeoTextOpen } = this.state;
    if (isSeoTextOpen) {
      scroller.scrollTo('seo-text-section__content', {
        duration: 200,
        delay: 100,
        offset: -100,
        smooth: 'easeInOutQuart',
      });

      Events.scrollEvent.register('end', () => {
        this.setState(prevState => ({
          isSeoTextOpen: !prevState.isSeoTextOpen,
        }));
      });
    } else {
      this.setState(prevState => ({
        isSeoTextOpen: !prevState.isSeoTextOpen,
      }));
    }
  };

  render() {
    const { isSeoTextOpen } = this.state;
    const { children } = this.props;

    return (
      <div className="seo-text-section__text">
        <div
          className={classNames({
            'seo-text-section__text__wrap': true,
            'seo-text-section__text__wrap_open': isSeoTextOpen,
            'seo-text-section__text__wrap_close': !isSeoTextOpen,
          })}
          aria-expanded={isSeoTextOpen}
        >
          {children}
        </div>

        <button
          type="button"
          onClick={() => this.toggleSeoText()}
          className={classNames({
            'seo-text-section__text__btn': true,
            'seo-text-section__text__btn_down': !isSeoTextOpen,
          })}
          aria-label="toggle seo text"
        />
      </div>
    );
  }
}

SeoTextContent.propTypes = {
  children: PropTypes.node,
};

SeoTextContent.defaultProps = {
  children: undefined,
};

export default SeoTextContent;
