import React from 'react';
import PropTypes from 'prop-types';


const SeoTextHeading = ({ children }) => (
  <h1 className="seo-text-section__heading h2">
    {children}
  </h1>
);

SeoTextHeading.propTypes = {
  children: PropTypes.node,
};

SeoTextHeading.defaultProps = {
  children: undefined,
};

export default SeoTextHeading;
